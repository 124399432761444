import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { CloudinaryImageType } from '../../../../../../types/blockTypes';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';

interface Props {
  illustration: CloudinaryImageType;
}

export const Illustration = ({ illustration }: Props) => {
  const cld = useCloudinary();
  const mainImage =
    (illustration && cld.image(illustration.publicId).delivery(dpr('3.0')).quality('auto:best').format('svg')) ||
    undefined;
  return (
    <StyledIllustration
      cldImg={mainImage}
      plugins={[
        lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
        responsive({ steps: 200 }),
        placeholder({ mode: 'blur' }),
      ]}
      alt={illustration.altText || illustration.caption}
    />
  );
};

const StyledIllustration = styled(AdvancedImage)`
  position: absolute;
  height: 474px;
  top: -110px;
  right: 0;
  @media (max-width: ${breakpoints.l}) {
    height: 300px;
    top: -100px;
  }
  @media (max-width: ${breakpoints.m}) {
    height: 184px;
    top: -80px;
  }
`;
